import React, { FC } from 'react'
import { DateRangePicker, Calendar } from 'react-date-range'
import { addDays } from 'date-fns'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

interface IDateSelection {
  selection: any
}

interface IProps {
  dateState: IDateSelection
  onDateChangeHandler: (item: any) => void
  calendar?: boolean
  calendarDate: Date
}

export const ExportCSVDateRange: FC<IProps> = ({
  dateState,
  onDateChangeHandler,
  calendar,
  calendarDate,
}) => {
  if (calendar)
    return (
      <Calendar
        date={calendarDate}
        onChange={(item) => onDateChangeHandler(item)}
        color={'#fff'}
        rangeColors={['rgba(94, 220, 181, 1)']}
        className='dateFilter'
      />
    )
  else
    return (
      <DateRangePicker
        onChange={(item: any) => onDateChangeHandler(item)}
        months={1}
        className='csvDateRange'
        minDate={addDays(new Date(), -400)}
        maxDate={new Date()}
        direction='horizontal'
        rangeColors={['rgba(94, 220, 181, 1)']}
        color={'#fff'}
        ranges={[dateState.selection]}
        fixedHeight={true}
      />
    )
}
