import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Button } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { FilterBox, FilterButton } from '../FilterPopoverWrapper'
import { ExportCSVDateRange } from '@/views/base/ExportCsv/exportCsvRange'
import { CustomButton } from '@/views/base/StyledButton/CustomButton'
import { IDateFilter } from '../../interface'

interface ISelection {
  startDate: Date | null
  endDate: Date | null
  key: string
}

const selection = {
  startDate: new Date(),
  endDate: null,
  key: 'selection',
}

const FilterDateRange = ({ fieldName, label, type, onSearchHandler, calendar }: IDateFilter) => {
  const [dateState, setDateState] = useState({ selection })
  const [calendarDate, setCalendarDate] = useState<any>(new Date())
  const [isOpen, setIsOpen] = useState(false)
  const onDateChangeHandler = (data: ISelection | Date) =>
    calendar ? setCalendarDate(data) : setDateState({ ...dateState, ...data })
  const toggleHandler = () => setIsOpen(!isOpen)
  const submitHandler = () => {
    const callback = () => {
      setDateState({ selection })
      setCalendarDate(new Date())
      toggleHandler()
    }
    function convertUTCDateToLocalDate(date: Date) {
      const newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
      return new Date(newDate)
    }

    if (calendar) {
      onSearchHandler(fieldName, { date: calendarDate }, type)
      setTimeout(() => {
        callback()
      }, 1000)
    } else {
      const startDate = convertUTCDateToLocalDate(dateState.selection.startDate)
      const endDate =
        dateState.selection.endDate && convertUTCDateToLocalDate(dateState.selection.endDate)

      const date = { ...dateState.selection, endDate, startDate }
      console.log('date===>date', startDate, date.startDate, date.endDate, date)

      if (date.endDate && date.startDate) {
        onSearchHandler(fieldName, date, type)
        setTimeout(() => {
          callback()
        }, 1000)
      }
    }
  }
  return (
    <>
      <FilterBox component='span'>
        <FilterButton
          data-testId={fieldName}
          variant='contained'
          onClick={toggleHandler}
          startIcon={<AddCircleIcon />}
        >
          {' '}
          {label}{' '}
        </FilterButton>
      </FilterBox>

      <Dialog open={isOpen} onClose={toggleHandler}>
        <Grid
          sx={{
            background: '#07081E',
            color: '#E2E2E2',
            border: '1px solid #35364B',
            borderRadius: '18px',
          }}
        >
          <DialogTitle
            style={{
              fontFamily: 'Montserrat',
              fontWeight: 500,
              fontSize: '0.975rem',
            }}
          >
            {calendar ? 'Filter By Date' : 'Filter By Date Range'}
          </DialogTitle>
          <DialogContent>
            <ExportCSVDateRange
              dateState={dateState}
              onDateChangeHandler={onDateChangeHandler}
              calendar={calendar}
              calendarDate={calendarDate}
            />
          </DialogContent>
          <DialogActions>
            <Button
              style={{ border: '1px solid grey', color: 'grey', marginRight: '10px' }}
              data-testId='DateFilterCancelBtn'
              type='button'
              onClick={toggleHandler}
            >
              Cancel
            </Button>
            <Grid mr={2}>
              <CustomButton
                clickHandler={submitHandler}
                testId='filterDateBtn'
                title='Apply'
                type='submit'
              />
            </Grid>
          </DialogActions>
        </Grid>
      </Dialog>
    </>
  )
}

export default FilterDateRange
