import React from 'react'
import { styled, TextField } from '@mui/material'
import { CustomButton } from '@/views/base/StyledButton/CustomButton'
import { FilterType } from '@/strict-typing/enums/portal.enum'

const StyledInputField = styled(TextField)(() => ({
  input: { color: '#E2E2E2' },
  border: '1px solid rgba(163, 165, 171, 0.2)',
  backgroundColor: '#0E0F29',
  borderRadius: '8px',
  width: '100%',
  marginTop: '8px',
  padding: 0,
  margin: '10px 0',
}))

interface IProps {
  fieldName: string
  onSearchHandler: (f: string, v: string, t: FilterType) => void
  closePopover: (e: React.MouseEvent<HTMLButtonElement>) => void
  type: FilterType
}

const FilterTextInput = ({ fieldName, onSearchHandler, closePopover, type }: IProps) => {
  const [search, setSearch] = React.useState('')
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearch(value)
  }
  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (search) {
      onSearchHandler(fieldName, search, type)
      setTimeout(() => {
        closePopover(e)
      }, 1000)
    }
  }
  return (
    <>
      <StyledInputField onChange={changeHandler} />
      <CustomButton title='Apply' applyFullWidth={true} clickHandler={submitHandler} />
    </>
  )
}

export default React.memo(FilterTextInput)
