import React, { FC, ChangeEvent } from 'react'
import styled from 'styled-components'
import { Grid, Typography } from '@mui/material'
import { Paginationfilter } from './Paginationfilter'
import { Pagination } from './Pagination'

const Wrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`
interface IProps {
  title: string
  totalRecords: string | number
  totalPage: number
  onLimitChange: (e: ChangeEvent<HTMLSelectElement>) => void
  onPageChange: (e: ChangeEvent<any>, newPage: number) => void
  defaultValue?: any
  page?: number
}

export const PaginationWrapper: FC<IProps> = ({
  title,
  totalRecords,
  totalPage,
  onLimitChange,
  onPageChange,
  ...rest
}) => {
  return (
    <>
      <Wrapper>
        <Grid>
          <Typography pt={2}>
            {title} {totalRecords}
          </Typography>
        </Grid>
        <Grid>
          <Paginationfilter
            defaultValue={rest?.defaultValue}
            onLimitChangeHandler={onLimitChange}
          />
        </Grid>
        <Grid>
          <Pagination page={rest?.page} totalPage={totalPage} onPageChangeHandler={onPageChange} />
        </Grid>
      </Wrapper>
    </>
  )
}
