import React, { FC } from 'react'
import { Grid, styled, Typography } from '@mui/material'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import { useFormContext, Controller } from 'react-hook-form'
import InputField from '@mui/material/Input'
interface IStyledForm {
  error: string
}
const StyledInputField: any = styled(InputField)(({ error }: IStyledForm) => ({
  input: { color: '#E2E2E2' },
  border: `${error ? '1px solid red' : '1px solid rgba(163, 165, 171, 0.2)'}`,
  backgroundColor: '#0E0F29',
  borderRadius: '8px',
  width: '100%',
  marginTop: '8px',
  padding: 5,
}))

interface Props {
  label: string
  fieldName: string
  type?: string
  placeHolder?: string
  disabled?: boolean
  currency: string
}

export const CurrencyField: FC<Props | any> = ({
  label,
  fieldName,
  placeHolder,
  currency,
  type,
  disabled,
  ...rest
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext()
  const error: any = errors && errors[fieldName] && errors[fieldName]?.message
  const inputLabel = label ? label.replace('*', '') : ''
  const inputRequired = label.includes('*') ? '*' : ''
  return (
    <>
      {inputLabel && (
        <InputLabel>
          <Typography variant='body2'>
            {inputLabel}&nbsp;
            <span
              style={{
                color: '#FF6347',
                fontSize: '15px',
              }}
            >
              {inputRequired}
            </span>
          </Typography>
        </InputLabel>
      )}
      <Controller
        name={fieldName}
        control={control}
        render={({ field }) => (
          <StyledInputField
            id='standard-adornment-amount'
            {...(currency
              ? {
                  startAdornment: (
                    <InputAdornment position='start'>
                      <span style={{ color: '#fff', paddingLeft: '5px' }}>{currency}</span>
                    </InputAdornment>
                  ),
                }
              : {})}
            data-testId={fieldName}
            placeholder={placeHolder || ''}
            type={type || 'text'}
            error={Boolean(error)}
            disabled={disabled}
            {...{ ...field, ...rest }}
          />
        )}
      />
      {error && (
        <Grid style={{ color: 'red', marginTop: '10px' }}>
          <Typography>{error}</Typography>
        </Grid>
      )}
    </>
  )
}
