import React from 'react'
import { Button, Grid, styled } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

const FilterButton = styled(Button)`
  color: rgba(255, 255, 255, 0.7);
  font-size: 10px;
  display: flex;
  min-width: 120px;
  font-size: 12px;
`

interface IProps {
  clearFilters: () => void
}

const ClearFilterBtn: React.FC<IProps> = ({ clearFilters }) => {
  return (
    <>
      <Grid>
        <FilterButton data-testId='clearFilters' startIcon={<CancelIcon />} onClick={clearFilters}>
          Clear Filters
        </FilterButton>
      </Grid>
    </>
  )
}

export default ClearFilterBtn
