export enum EPortals {
  VENDOR = 'vendor_portal',
  ADMIN = 'admin_portal',
  EMPLOYER = 'employer_portal',
}

export enum FilterType {
  TEXT = 'textInput',
  DATE = 'dateRangeInput',
  CHECKBOX = 'checkboxInput',
}

export enum USER_ROLES {
  SUPPORT = 'support',
  OPERATOR = 'operator',
  OWNER = 'owner',
  NONE = '',
}

export type TransactionStatus =
  | 'queued'
  | 'in progress'
  | 'complete'
  | 'rejected'
  | 'failed'
  | 'disputed'
  | 'waitingForApproval'
  | 'organizationApprovalRequired'
  | 'abhiPayAwaited'
  | 'declined'
  | 'blocked'
