interface PersistentStorage {
  getItem(key: string): string | null
  setItem(key: string, value: string): void
  removeItem(key: string): void
  clearItem(): void
}

class SessionStorage implements PersistentStorage {
  getItem(key: string) {
    const item = sessionStorage.getItem(key)
    if (item === null) return undefined
    if (item === 'null') return null
    if (item === 'undefined') return undefined

    try {
      return JSON.parse(item)
    } catch (error) {
      console.error(error)
    }

    return item
  }
  setItem(key: string, value: string) {
    sessionStorage.setItem(key, JSON.stringify(value))
  }
  removeItem(key: string) {
    sessionStorage.removeItem(key)
  }
  clearItem() {
    sessionStorage.clear()
  }
}

class MockStorage implements PersistentStorage {
  getItem() {
    return null
  }
  setItem() {
    return null
  }
  removeItem() {
    return null
  }
  clearItem() {
    return null
  }
}

export const persistentStorage = window?.sessionStorage ? new SessionStorage() : new MockStorage()
