import { HttpMethodType } from '@/strict-typing/types/api-manager.type'

export const backendFeatures = {
  EMPLOYEE_WEB: {
    transaction: [
      {
        internalName: 'TransactionEmployeeApproveTransaction',
        endPoint: 'transaction/employee_approve_transaction',
        httpMethod: 'POST',
      },
    ],

    manage: [
      {
        internalName: 'OnlyTitleFetch',
        endPoint: 'manage/titleFetch/only_title_fetch',
        httpMethod: 'POST',
      },
      {
        internalName: 'GetOrganizationManagerRole',
        endPoint: 'manage/{organizationId}/managers/{employeeId}/role',
        httpMethod: 'GET',
      },
      {
        internalName: 'OrganizationGetSettlements',
        endPoint: 'manage/organizations/settlements',
        httpMethod: 'GET',
      },
      {
        internalName: 'GetOrganizationBalanceStatement',
        endPoint: 'manage/one-salary/get-statement',
        httpMethod: 'POST',
      },
      {
        internalName: 'AuthChangePassword',
        endPoint: 'manage/auth/change-password',
        httpMethod: 'PATCH',
      },
      {
        internalName: 'OrganizationUpdateEmployer',
        endPoint: 'manage/organization',
        httpMethod: 'PATCH',
      },
    ],
    auth: [
      {
        internalName: 'AuthLogout',
        endPoint: 'logout',
        httpMethod: 'POST',
      },
      {
        internalName: 'MFAGenerate',
        endPoint: 'auth/mfa',
        httpMethod: 'POST',
      },
      {
        internalName: 'LoginAsOrganizationSupporter',
        endPoint: 'employee/login-sub-organization',
        httpMethod: 'POST',
      },
    ],
    employee: [
      {
        internalName: 'GetGroupedEmployeeCountForDiscrepancyTab',
        endPoint: 'employee/employees/grouped-employee-count-discrepancy',
        httpMethod: 'GET',
      },
      {
        internalName: 'GetOneSalaryBalance',
        endPoint: 'organization/get/balance',
        httpMethod: 'GET',
      },
      {
        internalName: 'EmployeeBulkUpload',
        endPoint: 'employee/organizations/employees/bulk',
        httpMethod: 'POST',
      },
      {
        internalName: 'EnableOrDisableEmployee',
        endPoint: 'employee/organizations/employees/disable-or-enable',
        httpMethod: 'POST',
      },
      {
        internalName: 'OrganizationDeleteEmployees',
        endPoint: 'employee/organizations/employees',
        httpMethod: 'DELETE',
      },
      {
        internalName: 'OrganizationEditEmployeesPut',
        endPoint: 'employee/organizations/employees/request',
        httpMethod: 'PUT',
      },
      {
        internalName: 'PostApproveTransactionEmployee',
        endPoint: 'employee/approve/employees/transaction',
        httpMethod: 'POST',
      },
      {
        internalName: 'GetAllEmployeeTransactionsImproved',
        endPoint: 'monitor/transactions/employees/get/all',
        httpMethod: 'GET',
      },
      {
        internalName: 'GetAllRepayments',
        endPoint: 'b2c/repayment',
        httpMethod: 'GET',
      },
      {
        internalName: 'PutApproveRepaymentEmployee',
        endPoint: 'b2c/repayment',
        httpMethod: 'PUT',
      },
      {
        internalName: 'GetAttachment',
        endPoint: 'medias/medias',
        httpMethod: 'GET',
      },
      {
        internalName: 'OrganizationMakerGetAllMyEmployees',
        endPoint: 'organizations/maker/myEmployees',
        httpMethod: 'GET',
      },
      {
        internalName: 'OrganizationCheckerGetAllMyEmployees',
        endPoint: 'employee/organizations/checker/myEmployees',
        httpMethod: 'GET',
      },
      {
        internalName: 'OrganizationDeleteEmployees',
        endPoint: 'organizations/employees',
        httpMethod: 'DELETE',
      },
      {
        internalName: 'GetSystemConfiguration',
        endPoint: 'settings/get/system-configurations',
        httpMethod: 'GET',
      },
      {
        internalName: 'OrganizationEmployeesOnBoarding',
        endPoint: 'on-boarding',
        httpMethod: 'POST',
      },
      {
        groupName: 'payroll',
        internalName: 'PayrollBulkUpload',
        endPoint: 'payroll/bulk-upload/{payrollId}/{initiator}',
        httpMethod: 'POST',
      },
      {
        groupName: 'employee',
        internalName: 'ActiveInactiveEmployeesByCnic',
        endPoint: 'employee/user-status-active-inactive',
        httpMethod: 'POST',
      },
      {
        internalName: 'OrganizationUpdateAllEmployee',
        endPoint: 'employee/update/all',
        httpMethod: 'PUT',
      },
      {
        internalName: 'OrganizationBulkUpdateEmployees',
        endPoint: 'employee/organizations/employees/bulk/update',
        httpMethod: 'POST',
      },
      {
        internalName: 'OrganizationEditEmployees',
        endPoint: 'organizations/employees/request',
        httpMethod: 'PUT',
      },
      {
        internalName: 'OrganizationAddEmployees',
        endPoint: 'employee/organizations/employees/add',
        httpMethod: 'POST',
      },
      {
        internalName: 'BulkEmployeeAction',
        endPoint: 'employee/user-status-active-inactive',
        httpMethod: 'POST',
      },
      {
        internalName: 'SendWelcomeSMS',
        endPoint: 'employee/on-boarding',
        httpMethod: 'POST',
      },
      {
        internalName: 'GetSTATS',
        endPoint: 'organization/stats',
        httpMethod: 'GET',
      },
      {
        internalName: 'GetB2CSTATS',
        endPoint: 'b2c/stats',
        httpMethod: 'GET',
      },
      {
        internalName: 'OrganizationEditEmployeesApproveOrReject',
        endPoint: 'employee/organizations/employees/approve-or-reject',
        httpMethod: 'PUT',
      },
      {
        internalName: 'OrganizationErrorReport',
        endPoint: 'employee/organization/errors',
        httpMethod: 'GET',
      },
      {
        internalName: 'ApplyBankAccountHistory',
        endPoint: 'organization/employees/apply-historical-employee-bank-details',
        httpMethod: 'PUT',
      },
      {
        internalName: 'GetHistoricalEMployeeBankAccountData',
        endPoint: 'organization/employees/get-historical-employee-bank-details',
        httpMethod: 'GET',
      },
      {
        internalName: 'GetEmployeeCount',
        endPoint: 'employee/count',
        httpMethod: 'GET',
      },
      {
        groupName: 'employee',
        internalName: 'DeleteEmployeesByCNICList',
        endPoint: 'employee/delete_employees_by_cnic',
        httpMethod: 'DELETE',
      },
    ],
    payroll: [
      {
        internalName: 'ProccessPayroll',
        endPoint: 'payroll/process/{payrollId}/{initiator}',
        httpMethod: 'POST',
      },
      {
        internalName: 'TogglePayrollAmountAll',
        endPoint: 'payroll/toggle/all/{payrollId}/{initiator}',
        httpMethod: 'POST',
      },
      {
        internalName: 'TogglePayrollAmount',
        endPoint: 'payroll/toggle/single/{payrollId}/{rowId}/{initiator}',
        httpMethod: 'POST',
      },
      {
        internalName: 'GetPayrollReadyList',
        endPoint: 'payroll/list/ready/{payrollId}/{initiator}',
        httpMethod: 'GET',
      },
      {
        internalName: 'GetPayrolls',
        endPoint: 'payroll/{initiator}',
        httpMethod: 'GET',
      },
      {
        internalName: 'PayrollMetaData',
        endPoint: 'payroll/metadata/{initiator}',
        httpMethod: 'GET',
      },
      {
        internalName: 'GetPayrollReadyList',
        endPoint: 'list/ready/{payrollId}/{initiator}',
        httpMethod: 'GET',
      },
      {
        internalName: 'PayrollStatus',
        endPoint: 'payroll/status/{payrollId}/{initiator}',
        httpMethod: 'GET',
      },
      {
        internalName: 'PostPreparePayroll',
        endPoint: 'prepare/{initiator}',
        httpMethod: 'POST',
      },
      {
        internalName: 'PostProcessPayroll',
        endPoint: 'process/{payrollId}/{initiator}',
        httpMethod: 'POST',
      },
      {
        internalName: 'PreparePayroll',
        endPoint: 'payroll/prepare/{initiator}',
        httpMethod: 'POST',
      },
      {
        internalName: 'deleteReadyPayroll',
        endPoint: 'payroll/delete/{payrollId}',
        httpMethod: 'DELETE',
      },
    ],
  },
  VENDOR_WEB: {
    auth: [
      {
        groupName: 'auth',
        internalName: 'AuthLogout',
        endPoint: 'auth/logout',
        httpMethod: 'POST' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'auth',
        internalName: 'GetUserThroughAuth',
        endPoint: 'auth/get-user-data',
        httpMethod: 'GET' as HttpMethodType,
        roles: ['Support'],
      },
      {
        groupName: 'auth',
        internalName: 'LoginWithExternal',
        endPoint: 'auth/portals/login',
        httpMethod: 'POST' as HttpMethodType,
      },
      {
        groupName: 'auth',
        internalName: 'FetchUserProfile',
        endPoint: 'manage/user/me',
        httpMethod: 'GET' as HttpMethodType,
      },
    ],
    settings: [
      {
        groupName: 'settings',
        internalName: 'SystemGetSettings',
        endPoint: 'settings/get/system/{initiator}',
        httpMethod: 'PUT' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'settings',
        internalName: 'VendorPOCs',
        endPoint: 'vendor/vendor/pocs/{organizationId}',
        httpMethod: 'PUT' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
    ],
    employee: [
      {
        groupName: 'employee',
        internalName: 'OrganizationUpdateAllEmployee',
        endPoint: 'organizations/employees',
        httpMethod: 'PUT' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'employee',
        internalName: 'OrganizationBulkUploadEmployees',
        endPoint: 'employee/organizations/employees/bulk',
        httpMethod: 'POST' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
    ],
    system: [
      {
        groupName: 'system',
        internalName: 'GetPreSignedUrl',
        endPoint: 'pre-signed-url-upload',
        httpMethod: 'GET' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
    ],
    bank: [
      {
        groupName: 'bank',
        internalName: 'OrganizationUpdateBankAccount',
        endPoint: 'employee/organizations/bank-accounts',
        httpMethod: 'PUT' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'bank',
        internalName: 'ManageGetAllBanks',
        endPoint: 'manage/banks',
        httpMethod: 'GET' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
    ],
    transaction: [
      {
        groupName: 'transaction',
        internalName: 'GetAllVendorTransactionRequest',
        endPoint: 'vendor/vendor/transactions',
        httpMethod: 'GET' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'transaction',
        internalName: 'CheckerApproveVendorTransactionRequest',
        endPoint: 'vendor/vendors/transactions/{transactionId}',
        httpMethod: 'PUT' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'transaction',
        internalName: 'VendorTransactionRequest',
        endPoint: 'vendor/vendor-transaction-request',
        httpMethod: 'POST' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
    ],
    vendor: [
      {
        groupName: 'vendor',
        internalName: 'GetAllVendor',
        endPoint: 'vendor/vendors',
        httpMethod: 'GET' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'vendor',
        internalName: 'DeleteVendor',
        endPoint: 'vendor/vendors',
        httpMethod: 'DELETE' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'vendor',
        internalName: 'CreateVendor',
        endPoint: 'vendor/vendors',
        httpMethod: 'POST' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'vendor',
        internalName: 'UpdateVendor',
        endPoint: 'vendor/vendors/{id}',
        httpMethod: 'PUT' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'vendor',
        internalName: 'CheckerApproveAllVendor',
        endPoint: 'vendors/approve-all',
        httpMethod: 'PUT' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'vendor',
        internalName: 'CheckerApproveAllVendorTransaction',
        endPoint: 'vendors/transaction/approve/all',
        httpMethod: 'PUT' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'vendor',
        internalName: 'PostBulkUploadVendors',
        endPoint: 'vendor/vendors/upload/bulk',
        httpMethod: 'POST' as HttpMethodType,
        roles: ['Support', 'Maker'],
      },
      {
        groupName: 'vendor',
        internalName: 'PostBulkPaymentsVendor',
        endPoint: 'vendor/vendors/payments/bulk',
        httpMethod: 'POST' as HttpMethodType,
        roles: ['Support', 'Maker'],
      },
      {
        groupName: 'vendor',
        internalName: 'GetSignedUrl',
        endPoint: 'medias/medias',
        httpMethod: 'GET' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'vendor',
        internalName: 'GetTurnkeyProducts',
        endPoint: 'third-parties/turnkey/products',
        httpMethod: 'GET' as HttpMethodType,
        roles: ['Support', 'Maker'],
      },
      {
        groupName: 'vendor',
        internalName: 'GetVendorOrganizationStatus',
        endPoint: 'vendor/vendors/get-organization-status',
        httpMethod: 'GET' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'vendor',
        internalName: 'BillPaymentGetAllBillsVendors',
        endPoint: 'vendor/bills-users',
        httpMethod: 'GET' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'vendor',
        internalName: 'GetAllVendorBillPaymentTransactionRequest',
        endPoint: 'vendor/bill-payments/transactions',
        httpMethod: 'GET' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'vendor',
        internalName: 'CheckerApproveVendorBillPaymentTransactionRequest',
        endPoint: 'vendors/bill-payments/transactions/{transactionId}',
        httpMethod: 'PUT' as HttpMethodType,
        roles: ['Support', 'Checker'],
      },
      {
        groupName: 'vendor',
        internalName: 'VendorPostBillPaymentRequest',
        endPoint: 'vendor/bill-payment',
        httpMethod: 'POST' as HttpMethodType,
        roles: ['Support', 'Maker'],
      },
      {
        groupName: 'vendor',
        internalName: 'BillPaymentPostBillsUsers',
        endPoint: 'bill-payments/bills/user/create/post-create',
        httpMethod: 'POST' as HttpMethodType,
        roles: ['Support', 'Maker'],
      },
      {
        groupName: 'vendor',
        internalName: 'GetPreSignedUrlForVendorTrx',
        endPoint: 'vendor/vendor/pre-signed-url-download-for-vendor-inv',
        httpMethod: 'GET' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'vendor',
        internalName: 'CreateVendorInvoice',
        endPoint: 'vendor/vendors/create-vendor-invoice',
        httpMethod: 'POST' as HttpMethodType,
        roles: ['Support', 'Maker'],
      },
      {
        groupName: 'vendor',
        internalName: 'GetVendorInvoiceBalance',
        endPoint: 'vendor/vendors/get-vendor-invoice-balance',
        httpMethod: 'GET' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'vendor',
        internalName: 'GetVendorInvoice',
        endPoint: 'vendor/vendors/get-vendor-invoice',
        httpMethod: 'GET' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'vendor',
        internalName: 'CheckerApproveVendorInvoice',
        endPoint: 'vendor/vendors/approve-invoice/{invoiceId}',
        httpMethod: 'PUT' as HttpMethodType,
        roles: ['Support', 'Checker'],
      },
      {
        groupName: 'vendor',
        internalName: 'CreateTurnkeyRepayment',
        endPoint: 'vendor/turnkey/create/repayment',
        httpMethod: 'POST' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'vendor',
        internalName: 'GetAllTurnkeyRepayment',
        endPoint: 'vendor/get/all/turnkey/repayment',
        httpMethod: 'GET' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'vendor',
        internalName: 'CheckerApproveRejectTurnkeyRepayment',
        endPoint: 'vendor/checker/approve/turnkey/repayment',
        httpMethod: 'PUT' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
    ],
    settlement: [
      {
        groupName: 'settlement',
        internalName: 'OrganizationGetVendorSettlements',
        endPoint: 'vendor/vendors/settlements',
        httpMethod: 'GET' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
    ],
    billPayment: [
      {
        groupName: 'Bill-Payment',
        internalName: 'BillPaymentDeleteBillsUsers',
        endPoint: 'bill-payments/bills/user/delete/{id}',
        httpMethod: 'DELETE' as HttpMethodType,
        roles: ['Support', 'Maker'],
      },
      {
        groupName: 'Bill-Payment',
        internalName: 'BillPaymentPatchUpdateBillsUsers',
        endPoint: 'bill-payment/update/bills-users/{id}',
        httpMethod: 'PATCH' as HttpMethodType,
        roles: ['Support', 'Maker'],
      },
      {
        groupName: 'Bill-Payment',
        internalName: 'BillPaymentPostBillInquiry',
        endPoint: 'bill-payments/bills/post-bill/inquiry',
        httpMethod: 'POST' as HttpMethodType,
        roles: ['Support', 'Maker'],
      },
      {
        groupName: 'Bill-Payment',
        internalName: 'BillPaymentsPostBillsUsers',
        endPoint: 'bill-payment/bills-users',
        httpMethod: 'POST' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'Bill-Payment',
        internalName: 'BillPaymentsGetAllBillsUsers',
        endPoint: 'bill-payment/bills-users',
        httpMethod: 'GET' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
      {
        groupName: 'Bill-Payment',
        internalName: 'BillPaymentGetAllBillers',
        endPoint: 'bill-payments/bills/biller/get/all',
        httpMethod: 'GET' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
    ],
    turnkey: [
      {
        groupName: 'turnkey',
        internalName: 'GetTurnKeyLoanDetails',
        endPoint: 'vendor/turnkey/loanDetails',
        httpMethod: 'GET' as HttpMethodType,
        roles: ['Support', 'Maker', 'Checker'],
      },
    ],
  },
}

export const getBackendFeatureByInternalName = (internalName: string): any => {
  const featureSetByInitiator = Object.values(backendFeatures)
  for (const element of featureSetByInitiator) {
    const featureGroups = Object.values(element)
    for (const elementChild of featureGroups) {
      const featureGroup = elementChild
      for (const elementInnerChild of featureGroup) {
        const featureObj = elementInnerChild
        if (featureObj.internalName === internalName) {
          return featureObj
        }
      }
    }
  }
}
