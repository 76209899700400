import { Link } from 'react-router-dom'
import { lighten, ListItem, styled } from '@mui/material'
import React from 'react'
import { BaseDesign } from '../../theme/BaseDesign/BaseDesign'
import { trackEvents } from '../../../utils/tracking/mix-panel'

interface INavItemProps {
  name: string
  path: string
  active?: boolean
  testId: string
}

const CustomLink = styled(Link)(
  ({ theme }) => `
    text-decoration: none;
    color: ${lighten(theme.palette.secondary.main, 0.9)};
    `,
)

export const NavItem: React.FC<INavItemProps> = ({ name, path, active, testId }) => {
  const { colors } = BaseDesign
  return (
    <ListItem
      sx={
        active
          ? {
              backgroundColor: colors.themeColorGreen,
              borderRadius: '22px',
            }
          : {}
      }
    >
      <CustomLink
        onClick={() => trackEvents(name)}
        to={path}
        data-testId={testId}
        sx={
          active
            ? {
                color: '#1D1446',
              }
            : {}
        }
      >
        {name}
      </CustomLink>
    </ListItem>
  )
}
