import authReducer from './auth/auth.reducer'
import * as extensionReducers from '@/extension-portal/state/reducers'
import { combineReducers, Reducer } from '@reduxjs/toolkit'

interface IReducers {
  [key: string]: Reducer<any>
}
const reducers: IReducers = {
  auth: authReducer,
}

for (const key of Object.entries(extensionReducers)) {
  const [name, reducerObject] = key
  reducers[name] = reducerObject?.reducer
}

const rootReducer = combineReducers({
  ...reducers,
})

export default rootReducer
