import React from 'react'
import { Popover, Typography, styled, Grid } from '@mui/material'
import { FilterType } from '@/strict-typing/enums/portal.enum'
import FilterTextInput from './Inputs/FilterTextInput'
import FilterCheckboxInput from './Inputs/FilterCheckboxInput'
import { IFilterPopover } from '../interface'

const PopoverWrapper = styled(Grid)`
  background: #0e0f29;
  font-size: 10px;
  padding: 15px;
  width: 305px;
  color: #fff;
`
const PopoverHeading = styled(Typography)`
  font-size: 0.875rem;
  font-family: Montserrat;
  font-weight: bold;
  color: #fff;
  padding-top: 10px;
`

const FilterPopover: React.FC<IFilterPopover> = ({
  id,
  open,
  anchorEl,
  handleClose,
  fieldName,
  label,
  type,
  options,
  onSearchHandler,
  isMultiple,
}) => {
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{
          textTransform: 'capitalize',
          marginTop: '15px',
          borderRadius: '10px',
        }}
      >
        <PopoverWrapper>
          <PopoverHeading>Filter By {label}</PopoverHeading>
          {type === FilterType.CHECKBOX ? (
            <FilterCheckboxInput
              fieldName={fieldName}
              onSearchHandler={onSearchHandler}
              type={type}
              options={options}
              isMultiple={isMultiple}
              closePopover={handleClose}
            />
          ) : (
            <FilterTextInput
              fieldName={fieldName}
              onSearchHandler={onSearchHandler}
              closePopover={handleClose}
              type={type}
            />
          )}
        </PopoverWrapper>
      </Popover>
    </>
  )
}

export default FilterPopover
