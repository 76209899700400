import { EPortals } from '../../strict-typing'

export const AppConfig = {
  basePortal: {
    modules: ['login'],
  },
  extensionPortal: {
    name: EPortals.VENDOR,
    modules: ['Payments', 'Vendors', 'Statements'],
  },
  title: 'Abhi finance',
}
