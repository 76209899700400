import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IResults } from '@/extension-portal/types/employee/entities'

interface ISelectedRows {
  id: string
  isApproved: boolean
  selectedBankAccountId: string
  adminApproval: null
  approvalStatus: null
  bankGivenAccountTitle: string
  createdAt: string
  createdById: string
  dateOfJoining: string
  deletedDate: null
  department: { name: string }
  departmentId: string
  designation: { designation: string }
  designationId: string
  employeeCode: null
  employeesBadge: string
  homeAddress: null
  homeCity: null
  isSMSsent: boolean
  limit: number
  netSalary: string
  officialEmail: { email: string }
  officialEmailId: string
  officialPhone: null
  officialPhoneId: null
  organization: {
    id: string
    createdById: string
    createdAt: string
    deletedDate: null
    name: string
    // Other properties of organization...
  }
  organizationApproval: null
  organizationEmployeesConfigurations: any[] // Replace "any" with the appropriate type
  organizationId: string
  organizationManagers: any[] // Replace "any" with the appropriate type
  pendingChanges: null
  person: {
    id: string
    cnic: string
    createdById: string
    createdAt: string
    deletedDate: null
    user: any // Replace "any" with the appropriate type
    // Other properties of person...
  }
  personId: string
  providentFund: string
  selectedBankAccount: {
    id: string
    createdById: string
    createdAt: string
    deletedDate: null
    accountTitle: string
    // Other properties of selectedBankAccount...
  }
  systemAccess: string
  updatedAt: string
  updatedById: string
  userId: string
  verified: null
  version: number
  workingStatus: string
}

export interface IEmployees {
  isLoading: boolean
  employees: IResults[]
  page: number
  limit: number
  totalPage: number
  totalUsers: number
  selectedRows: ISelectedRows[]
  isAllRowsSelected: boolean
}

export const initialState: IEmployees = {
  isLoading: false,
  employees: [],
  page: 1,
  limit: 10,
  totalPage: 0,
  selectedRows: [],
  totalUsers: 0,
  isAllRowsSelected: false,
}

export const employeeReducer = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setIsLoading: (state: IEmployees, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setEmployees: (state: IEmployees, action: PayloadAction<any[]>) => {
      state.employees = action.payload
    },
    setPage: (state: IEmployees, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setLimit: (state: IEmployees, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    setTotalPage: (state: IEmployees, action: PayloadAction<number>) => {
      state.totalPage = action.payload
    },
    setSelectedRows: (state: IEmployees, action: PayloadAction<ISelectedRows[]>) => {
      state.selectedRows = action.payload
    },
    setIsAllRowsSelected: (state: IEmployees, action: PayloadAction<boolean>) => {
      state.isAllRowsSelected = action.payload
    },
    setTotalUsers: (state: IEmployees, action: PayloadAction<number>) => {
      state.totalUsers = action.payload
    },
  },
})

export const {
  setIsLoading,
  setPage,
  setLimit,
  setTotalPage,
  setSelectedRows,
  setEmployees,
  setIsAllRowsSelected,
  setTotalUsers,
} = employeeReducer.actions
export default employeeReducer.reducer
