import React from 'react'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'

import useYupValidationResolver from '@/utils/validation/validation.resolver'
import { Grid, Typography } from '@mui/material'
import { CustomButton } from '../StyledButton/CustomButton'

const Button = styled.button`
  background: transparent;
  border-radius: 4px;
  border: 1px solid #35364b;
  color: rgba(255, 255, 255, 0.7);
  margin-right: 10px;
  padding: 11px 20px;
  cursor: pointer;
`
interface IProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: ({
    email,
    fileType,
    reset,
  }: {
    email: string
    fileType: string
    reset: () => void
  }) => void
  title: string
}

interface IForm {
  email: string
}

export const ExportCsvModal: React.FC<IProps> = ({ isOpen, onClose, onSubmit, title }) => {
  const [fileType, setFileType] = React.useState('disabled')
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IForm>({
    resolver: useYupValidationResolver(
      yup.object().shape({
        email: yup
          .string()
          .email('Please type valid Email address')
          .required('Please fill out this Field'),
      }),
    ),
  })
  return (
    <Grid>
      <Dialog open={isOpen} onClose={onClose}>
        <Grid
          sx={{
            background: '#07081E',
            color: '#E2E2E2',
            border: '1px solid #35364B',
            borderRadius: '18px',
          }}
        >
          <DialogTitle>{title}</DialogTitle>
          <form onSubmit={handleSubmit(({ email }) => onSubmit({ email, fileType, reset }))}>
            <DialogContent className='exportCsvDialog'>
              <DialogContentText sx={{ color: '#E2E2E2', fontSize: '15px' }}>
                Please enter an Email you want to receive export data!
              </DialogContentText>
              <TextField
                {...register('email')}
                autoFocus
                margin='dense'
                id='name'
                label='Email Address'
                type='email'
                fullWidth
                variant='standard'
                data-testId='exportCsvInput'
              />
              {errors?.email?.message && (
                <Grid style={{ color: 'red', marginTop: '10px' }}>
                  <Typography>{errors?.email?.message}</Typography>
                </Grid>
              )}
              <Grid mt='10px' pr={2}>
                <select
                  name='file-type'
                  style={{
                    background: '#0E0F29',
                    width: '200px',
                    borderRadius: '4px',
                    border: '1px solid rgba(163, 165, 171, 0.2)',
                    padding: '7px',
                    color: '#E2E2E2',
                  }}
                  onChange={(e) => setFileType(e?.target?.value)}
                >
                  <option value='disabled' selected>
                    Select File Type
                  </option>
                  <option value='text/csv'>CSV</option>
                  <option value='text/xls'>XLS</option>
                  <option value='text/xlsx'>XLSX</option>
                </select>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button data-testId='exportCsvCancelBtn' type='button' onClick={onClose}>
                Cancel
              </Button>
              <Grid mr={2}>
                <CustomButton testId='exportCsvCancelBtn' title='Export' type='submit' />
              </Grid>
            </DialogActions>
          </form>
        </Grid>
      </Dialog>
    </Grid>
  )
}
