import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { RiFolderOpenFill } from 'react-icons/ri'
import { CSVReader, jsonToCSV } from 'react-papaparse'
import { Button, Grid, Typography, Link, Tooltip } from '@mui/material'
import { toast } from 'react-toastify'
import { trackEvents } from '@/utils/tracking/mix-panel'
import { mixPanelEvents } from '@/constants/tracking/mixPanel-events'
import { useFetchSingleton } from '@/utils/api-manager/useFetch'

const FILE_LINES = 200

interface IFileUploadRes {
  total: number
  validData: number
  inValidData: number
}

interface IProps {
  scope: string
  endpointGroup: string
  setIsLoading: (val: boolean) => void
  redirect?: string
  title?: string
  desc?: string
  module: string
  pathVariables?: any
  parts?: boolean
  cb?: any
  allowDownloadTemplate?: boolean
  downloadTemplateTitle?: string
  downloadTemplateUrl?: string
  parser?: boolean
}

const fetchApiInstance = useFetchSingleton.getInstance()
export const CsvUploader = ({
  redirect,
  title,
  desc,
  scope,
  endpointGroup,
  module,
  pathVariables = {},
  parts,
  cb,
  allowDownloadTemplate,
  downloadTemplateTitle,
  downloadTemplateUrl,
  parser = false,
}: IProps) => {
  const navigate = useNavigate()
  const csvHeader = useRef<string[]>([])
  const [csvData, setCSVData] = useState<any[]>([])
  const [loader, setLoader] = useState<boolean>(false)
  const handleOnDrop = (e: any) => {
    let tmp: any[] = []
    csvHeader.current = e.shift().data.map((i: string) => i.trim())
    const data = e
      .reduce((arr: any[], line: any) => {
        if (tmp.length === 0) arr.push(tmp)

        if (tmp.length === FILE_LINES) {
          arr[arr.length - 1] = [...tmp]
          tmp = []
          arr.push(tmp)
        }

        if (line.data?.join('') !== '') tmp.push(line.data)

        return arr
      }, [])
      .filter((i: string[]) => i.length)

    setCSVData(data)
  }
  const handleOnError = (e: any) => {
    console.error('handleOnError => ', e)
    setCSVData([])
  }

  const handleOnRemoveFile = () => {
    setCSVData([])
  }

  const uploadFileToS3 = (
    url: string | URL,
    blob: string | ArrayBufferView | ArrayBuffer | Blob | Document | FormData | null | undefined,
  ) => {
    return new Promise((resolve) => {
      const oReq = new XMLHttpRequest()
      oReq.open('PUT', url, true)
      oReq.onload = resolve
      oReq.send(blob)
    })
  }

  // fileName without extension
  const processUploadedFile = (fileName: string, part?: number, totalFiles?: number) =>
    fetchApiInstance.fetcher(endpointGroup, {
      pathVariables,
      fileName,
      part,
      totalFiles,
    })

  const getPreSignedUrl = async (fileName: string) => {
    const response = await fetchApiInstance.fetcher('GetSignedUrl', {
      fileName: fileName,
      fileType: 'text/csv',
      requestType: 'upload',
      scope,
    })
    return response?.data?.data?.signedUrl
  }

  const handleUploadFiles = async () => {
    if (csvData.length <= 0) {
      toast.error('please upload the CSV file!')
      return
    }
    toast.info('File upload is in progress')
    setLoader(true)

    if (parser) {
      const cnicArr = csvData?.[0].flat()
      const res = await fetchApiInstance.fetcher(endpointGroup, { cnic: cnicArr });
      if (res?.status === 200) {
        toast.success('CSV successfully uploaded!');
        if (redirect) navigate(redirect)
      }
      else {
        toast.error(res?.data.message || 'Please try again later!')
      }
      setLoader(false)
    } else {
      const fileName = `csv-${new Date().getTime()}`
      const totalParts = csvData.length
      const fileUploadPromises = []
      try {
        for (let i = 0; i < totalParts; i += 1) {
          const csvContent = jsonToCSV({ fields: csvHeader.current, data: csvData[i] })
          const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
          const signedUrl = await getPreSignedUrl(`${fileName}-${i}.csv`)
          await uploadFileToS3(signedUrl, blob)
          if (parts) fileUploadPromises.push(processUploadedFile(`${fileName}`, i, totalParts))
          else fileUploadPromises.push(processUploadedFile(`${fileName}-${i}.csv`, i, totalParts))
        }
        const fileUploadRes = await Promise.all(fileUploadPromises)
        const invalidRecords = fileUploadRes[0]?.data.data.inValidData.length;
        if (invalidRecords > 0) {
          toast.error(`${invalidRecords} Invalid Record(s) found in CSV`);
          setLoader(false)
        }
        else {
          toast.success('CSV successfully uploaded!')
          setLoader(false)
          trackEvents(mixPanelEvents.addBulkSuccess(module))
          if (redirect) navigate(redirect)
          if (cb) cb()
        }
      } catch (e: any) {
        toast.error(e.response?.data?.message || 'Please try again later!')
        setLoader(false)
        trackEvents(mixPanelEvents.addBulkFailed(module))
      } finally {
        setLoader(false)
      }
    }
  }
  return (
    <Grid>
      <Grid padding='12px 30px'>
        {title && (
          <Typography paddingY={2} variant='body1'>
            {title}
          </Typography>
        )}

        {desc && (
          <Typography paddingY={2} variant='body2'>
            {desc}
          </Typography>
        )}

        {allowDownloadTemplate && (
          <Grid paddingBottom={2}>
            <Tooltip title={downloadTemplateTitle}>
              <Link
                target='_blank'
                variant='body2'
                paddingBottom='10px'
                color='rgba(255, 255, 255, 0.9)'
                href={downloadTemplateUrl}
              >
                Download Template
              </Link>
            </Tooltip>
          </Grid>
        )}
        <CSVReader
          onDrop={handleOnDrop}
          onError={handleOnError}
          data-testId='csvReader'
          addRemoveButton
          removeButtonColor='#659cef'
          onRemoveFile={handleOnRemoveFile}
        >
          <RiFolderOpenFill fontSize='54px' color='#13384A' />
        </CSVReader>
      </Grid>
      <Grid px={4} py={2}>
        <Button
          className='uploadBtn'
          disabled={loader}
          data-testId='uploadBtnClicked'
          onClick={handleUploadFiles}
        >
          {csvData?.length ? 'Start' : 'Upload'}
        </Button>
      </Grid>
    </Grid>
  )
}
