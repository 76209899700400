import { USER_ROLES } from '@/strict-typing/enums/portal.enum'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useNavigate } from 'react-router-dom'
import { IOrganizationsConfigurations } from '../../../strict-typing/interfaces/login.interface'

export interface AuthError {
  message: string
}

export interface AuthState {
  isAuthenticated: boolean
  currentUser?: CurrentUser
  isLoading: boolean
  role: USER_ROLES
  error: AuthError
  organizationInfo: IOrganizationsConfigurations | Record<any, any>
}

export interface CurrentUser {
  id: string
  display_name: string
  email: string
  photo_url: string
}
export const initialState: AuthState = {
  isAuthenticated: false,
  isLoading: false,
  role: USER_ROLES.NONE,
  error: { message: 'An Error occurred' },
  organizationInfo: {
    allowVendorBillPayments: false,
    allowVendorBulkPayments: false,
    allowVendorInvoices: false,
    organizationId: '',
  },
}

export const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLoading: (state: AuthState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    navigateToLogin: () => {
      const navigate = useNavigate()
      navigate('/login')
    },
    setOrganizationInfo: (
      state: AuthState,
      action: PayloadAction<IOrganizationsConfigurations | Record<any, any>>,
    ) => {
      state.organizationInfo = action.payload
    },
    setRole: (state: AuthState, action: PayloadAction<string>) => {
      state.role = action.payload as USER_ROLES
    },
  },
})

export const { setIsLoading, navigateToLogin, setRole, setOrganizationInfo } = authReducer.actions
export default authReducer.reducer
