import React, { FC } from 'react'
import InputMask from 'react-input-mask'
import InputLabel from '@material-ui/core/InputLabel'
import { useFormContext, Controller } from 'react-hook-form'
import { Grid, styled, Typography } from '@mui/material'

interface IStyledForm {
  error: any
  name: string
}
const StyledMaskInput = styled(InputMask)(({ error }: IStyledForm) => ({
  input: { color: '#E2E2E2' },
  border: `${error ? '1px solid red' : '1px solid rgba(163, 165, 171, 0.2)'}`,
  backgroundColor: '#0E0F29',
  borderRadius: '8px',
  width: '100%',
  marginTop: '8px',
  padding: 10,
  color: '#fff',
  font: 'inherit',
  height: 40,
  boxSizing: 'border-box',
}))

interface Props {
  label: string
  mask: string
  width?: string
  fieldName: string
  placeHolder?: string
  rules?: any
}

export const InputMaskField: FC<Props> = ({
  label,
  mask,
  width,
  fieldName,
  placeHolder,
  rules,
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext() as any
  const props: any = {}
  const inputLabel = label ? label.replace('*', '') : ''
  const inputRequired = label.includes('*') ? '*' : ''
  if (width) props.style = { width }
  const error = errors && errors[fieldName] && errors[fieldName]?.message
  return (
    <>
      {inputLabel && (
        <InputLabel>
          <Typography variant='body2'>
            {inputLabel}&nbsp;
            <span
              style={{
                color: '#FF6347',
                fontSize: '15px',
              }}
            >
              {inputRequired}
            </span>
          </Typography>
        </InputLabel>
      )}
      <Controller
        name={fieldName}
        rules={rules}
        control={control}
        render={({ field: { ref, ...field } }) => (
          <StyledMaskInput
            {...{ ...field, ...props }}
            data-testId={fieldName}
            placeholder={placeHolder || ''}
            inputRef={ref}
            error={error}
            mask={mask}
            formNoValidate={false}
          />
        )}
      />
      {error && (
        <Grid style={{ color: 'red', marginTop: '10px' }}>
          <Typography>{error}</Typography>
        </Grid>
      )}
    </>
  )
}
