import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { InputBase, styled } from '@mui/material'
import { CustomHeader, CustomSearch } from '../../StyledHeader'
import { IOnSearchHandler } from '../interface'
import { FilterType } from '@/strict-typing/enums/portal.enum'

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  borderRadius: '32px',
  backgroundColor: '#0E0F29',
  height: '32px',
  width: '250px',
  border: ' 0.5px solid #363748',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  paddingRight: 40,
}))

export interface IProps {
  searchHandler: IOnSearchHandler
  name: string
  searchValue:string
  setSearchValue:any
}

const CustomSearchFilter = (props: IProps) => {
  const eventHandler = (event: any) => {
    if (event.key === 'Enter') {
      return props?.searchHandler(props?.name, props?.searchValue, FilterType.TEXT)
    }
  }
  return (
    <>
      <CustomHeader>
        <CustomSearch>
          <SearchIcon />
        </CustomSearch>
        <StyledInputBase
          onKeyDown={eventHandler}
          data-testId='searchFilter'
          placeholder='Search…'
          value={props?.searchValue}
          onChange={(e) => props?.setSearchValue(e.target.value)}
          inputProps={{ 'aria-label': 'search' }}
        />
      </CustomHeader>
    </>
  )
}

export default CustomSearchFilter
