export const mixPanelEvents = {
  // login
  loginPageLanded: 'Login Screen - Landed',
  loginBtnClick: 'Login Screen - Sign In',
  loginSuccess: 'Login (Success)',
  loginFailed: 'Login (Fail)',
  loginTotalTimeSpent: 'Time spent on login screen',

  // homePage
  homePageLanded: 'Vendors Home Screen Page Landed',
  homePageMakeAPaymentBtnClicked: 'Vendor Home Screen - Make a Payment ',
  // homepageactivites =>

  // Vendor Tab
  vendorTab: 'Vendor Tab ',
  addVendorBtnClicked: 'Vendors Page - All vendors - Add a Vendor',
  addVendorPostBtnClicked: 'Vendors Page - All vendors - Add a Vendor Post',
  editVendorBtnClicked: 'Vendors Page - All vendors - Edit a Vendor',
  approveVendorBtnClicked: 'Vendors Page - Approve Vendor',
  vendorTabs: (name: string) => `Vendors Page - ${name} vendors`,

  vendorSearchFilter: 'Vendor Page - Search Filters',
  vendorClearFilter: 'Vendor Page - Clear Filters',

  vendorPageMakeAPaymentSubMenu: 'Vendors Page - Sub Menu - Create Payment',
  vendorPageEditVendorSubMenu: 'Vendors Page - Sub Menu - Edit Vendor',
  deleteVendorBtnClicked: 'Vendors Page - Sub Menu - Edit Vendor',

  // addSingleVendor: 'Add a Vendor',
  // addBulkVendor: 'Add a Vendor',
  cancelAddVendor: 'Add a Vendor - cancel',
  selectBankTitleFetched: 'Add a Vendor - single', // question

  titleFetchedBtnClicked: 'Add a Vendor - single - title fetch',
  titleFetchedSuccess: 'Add a Vendor - single  title fetch success',
  titleFetchedFailed: 'Add a Vendor - single  title fetch failed',

  addVendorFailed: 'Add a Vendor - single - add a vendor failed',
  addVendorSuccess: 'Add a Vendor - single -vendor added',
  // addVendorBulk: 'Add a Vendor - bulk',

  // payment tab
  // lisitng
  allPaymentTab: 'Payments - All',
  paymentTabSearchFilter: 'Payments - All - Search Filters',
  paymentClearBtnClicked: 'Payments - Clear - Search Filters',
  paymentExportCSV: 'Payments - Export CSV',
  makeAPaymentBtnClicked: 'Make a payment',
  approvePayemntBtnClicked: 'Payment Page - Approve Payment',
  rejectPayemntBtnClicked: 'Payment Page - Reject Payment',

  // create payment
  makeApaymentCancelledBtnClicked: 'Make a payment - single - cancel',
  makeApaymentBtnClicked: 'Make a payment - single - make a payment',
  makeApaymentSuccess: 'Make a payment - single - Success',
  makeApaymentFailed: 'Make a payment - single - Failed',

  makeApaymentDragandDrop: 'Make a payment - single - invoice',
  makeApaymentDownloadBtnClicked: 'Make a payment - bulk - download template',
  // makeApaymentUploadFile: 'Make a payment - bulk - uplaod file',

  // statement tab
  exportStatements: 'Statements - export',
  exportStatementsCancelled: 'Statements - export - cancelled',
  statementsSearchClear: 'Statements - searchm- clear filters',

  // others
  logoutClicked: 'Logout',
  contactUs: 'Contact Us',

  // methods
  addBulkSuccess: (name: string) => {
    return `${name} - bulk upload - success`
  },
  addBulkFailed: (name: string) => {
    return `${name} - bulk upload - failed`
  },

  // POC events
  selectSettingsIcon: 'Select Settings Icon',
  cancelJourneyPocScreen: 'Cancel Journey from POC screen',
  selectPocIcon: 'Select POC icon',
  cancelJourneyPocEmailsScreen: 'Cancel Journey from POC Emails screen',
  enterEmailMaker: 'Enter email for maker',
  confirmAddEmailMaker: 'Confirm/Add email for maker',
  editMakerEmail: 'Edit maker email',
  confirmEditMakerEmail: 'Confirm edit for maker email',
  cancelEditMakerEmail: 'Cancel edit for maker email',
  deleteEmailMaker: 'Delete email from maker section',
  enterEmailChecker: 'Enter email for checker',
  confirmAddEmailChecker: 'Confirm/Add email for checker',
  editCheckerEmail: 'Edit checker email',
  confirmEditCheckerEmail: 'Confirm edit for checker email',
  cancelEditCheckerEmail: 'Cancel edit for checker email',
  deleteEmailChecker: 'Delete email from checker section',

  // Repayments events
  selectLoanStatusTab: 'Click on loan status tab',
  makeRepaymentSubtab: 'Click make a repayment tab',
  LoanStatusTabs: (name: string) => `Loan Status Page - ${name} tab from loan status tab`,
  searchFilterLoanStatusTab: 'Search filters on loan status tab',
  selectRepaymentBtnLoanStatusTab: 'Select repayment button from loan status screen ',
  selectCreateRepaymentScreen: 'On create a repayment screen',
  cancelCreateRepaymentScreen: 'Cancel create a repayment journey',
  submitCreateRepayment: 'Submit repayment request ',
  cancelCreateRepayment: 'Cancel repayment request',
  selectPendingApprovalTabRepaymentTab: 'Goes to pending approval in repayments tab',
  approveRequestRepaymentTab: 'Approve repayment request',
  rejectRequestRepaymentTab: 'Reject repayment request',
}
