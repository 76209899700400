import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import { CustomButton } from '../StyledButton/CustomButton'

const Button = styled.button`
  background: transparent;
  border-radius: 4px;
  border: 1px solid #35364b;
  color: rgba(255, 255, 255, 0.7);
  margin-right: 10px;
  padding: 11px 20px;
  cursor: pointer;
`
interface IProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: ({ email, reset }: { email: string; reset: () => void }) => void
  title: string
}

export const SendSmsModal: React.FC<IProps> = ({ isOpen, onClose, onSubmit, title }) => {
  return (
    <Grid>
      <Dialog open={isOpen} onClose={onClose}>
        <Grid
          sx={{
            background: '#07081E',
            color: '#E2E2E2',
            border: '1px solid #35364B',
            borderRadius: '18px',
          }}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ color: '#E2E2E2', fontSize: '15px' }}>
              Are you sure you want to send sms/email to selected employees?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button data-testId='exportCsvCancelBtn' type='button' onClick={onClose}>
              Cancel
            </Button>
            <Grid mr={2}>
              <CustomButton
                clickHandler={onSubmit}
                testId='exportCsvCancelBtn'
                title='Submit'
                type='submit'
              />
            </Grid>
          </DialogActions>
        </Grid>
      </Dialog>
    </Grid>
  )
}
