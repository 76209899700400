import React, { Suspense, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Loading } from './views/base'
import { AppConfig } from './config'
import './styles/style.scss'

const DefaultLayout = React.lazy(() =>
  import('./layout/DefaultLayout').then((module) => ({ default: module.default })),
)

const Login = React.lazy(() =>
  import('./views/pages/index').then((module) => ({ default: module.LoginPage })),
)

function App() {
  useEffect(() => {
    document.title = AppConfig.title
  }, [])
  return (
    <GoogleReCaptchaProvider reCaptchaKey='6Lca3OsnAAAAACYs16_r_okEbezkC2pyurK-71gZ'>
      <Router>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='*' element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </Router>
    </GoogleReCaptchaProvider>
  )
}

export default App
