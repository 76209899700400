import React, { FC } from 'react'
import { Grid } from '@mui/material'

const styles = {
  select: {
    background: '#0E0F29',
    width: '100%',
    borderRadius: '8px',
    border: '1px solid rgba(163, 165, 171, 0.2)',
    padding: '6px 0',
    marginTop: '10px',
    color: '#E2E2E2',
  },
}

interface IProps {
  onLimitChangeHandler: (e: React.ChangeEvent<HTMLSelectElement>) => void
  defaultValue?: any
}
export const Paginationfilter: FC<IProps> = ({ onLimitChangeHandler, ...rest }) => {
  return (
    <Grid>
      <Grid>
        <select
          style={styles.select}
          data-testId='selectPaginationLimit'
          onChange={onLimitChangeHandler}
          name='select'
          defaultValue={rest?.defaultValue}
        >
          <option value='10'>10 Items Per Page</option>
          <option value='50'>50 Items Per Page</option>
          <option value='100'>100 Items Per Page</option>
        </select>
      </Grid>
    </Grid>
  )
}
