import { styled } from '@mui/material'
import React from 'react'

const StyledSearch = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    // marginLeft: theme.spacing(3),
    width: 'auto',
    height: '100%',
  },
}))

interface Props {
  children?: React.ReactNode
}

export const CustomHeader: React.FC<Props> = ({ children }) => {
  return <StyledSearch>{children}</StyledSearch>
}
