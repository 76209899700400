import React, { FC, memo } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { styled } from '@mui/material'

interface IListingTable {
  rows: any
  columns: any[]
  limit: number
  rowId?: string
}

export const ListingTable: FC<IListingTable> = ({ rows, columns, limit, rowId }) => {
  const id = rowId || 'id'
  const StyleTable = styled(DataGrid)(({ theme }) => ({
    color: ' rgba(255, 255, 255, 0.7)',
    justifyContent: 'center',
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'Center',
    },
  }))

  return (
    <StyleTable
      sx={{
        border: 'none',
      }}
      rows={rows}
      columns={columns}
      pageSize={limit}
      rowsPerPageOptions={[limit]}
      autoHeight={true}
      disableColumnFilter
      disableColumnSelector
      getRowId={(row: any) => row?.[id]}
    />
  )
}

export default memo(ListingTable)
