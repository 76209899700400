import React from 'react'
import { Button, styled } from '@mui/material'
import { BaseDesign } from '@/views/theme/BaseDesign/BaseDesign'

const CustomBtn = styled(Button)(
  () => `
  margin-top: 8px;
  box-sizing: border-box;
  :hover {
    color: #fff;
    background: rgb(163, 165, 171);
  }
`,
)

type IButtonType = 'submit' | 'button'
type IStyle = Record<string, string>

interface IProps {
  title: string
  disabled?: boolean
  type?: IButtonType
  applyFullWidth?: boolean
  clickHandler?: (val?: any) => void
  testId?: string
  backgroundColor?: string
  color?: string
  style?: IStyle
}

export const CustomButton: React.FC<IProps> = ({
  title,
  applyFullWidth = false,
  type,
  clickHandler,
  disabled = false,
  testId = '',
  backgroundColor,
  color,
  style = {},
}) => {
  const { colors } = BaseDesign
  const hasClickHandler = typeof clickHandler === 'function'
  const bgColor = backgroundColor || colors.themeColorGreen
  const btnColor = color || '#1D1446'
  return (
    <CustomBtn
      style={style}
      fullWidth={applyFullWidth}
      variant='contained'
      disabled={disabled}
      onClick={() => (hasClickHandler ? clickHandler() : null)}
      type={type || 'button'}
      sx={{ background: bgColor, color: btnColor, fontWeight: '600', ...style }}
      data-testId={testId}
    >
      {title}
    </CustomBtn>
  )
}
