import React, { FC, memo } from 'react'
import { DataGrid, GridRowModel, GridEditMode } from '@mui/x-data-grid'
import { styled } from '@mui/material'

interface IExperimentalFeatures {
  newEditingApi?: boolean
}
interface OptionalProps {
  experimentalFeatures?: IExperimentalFeatures
  editMode?: GridEditMode
  processRowUpdate?: (newRow: GridRowModel) => GridRowModel
}
interface IDataTable extends OptionalProps {
  rows: any
  columns: any[]
  limit: number
  rowId?: string
  loading?: boolean
}

export const DataTable: FC<IDataTable> = ({
  rows,
  columns,
  limit,
  rowId,
  experimentalFeatures,
  editMode,
  processRowUpdate,
  loading,
}) => {
  const StyleTable = styled(DataGrid)(({ theme }) => ({
    color: ' rgba(255, 255, 255, 0.7)',
    justifyContent: 'center',
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'Center',
    },
    '& .MuiDataGrid-overlay': {
      backgroundColor: 'transparent !important',
    },
    '& .MuiCircularProgress-root': {
      color: 'rgba(255,255,255,0.7)',
    },
  }))
  const id = rowId || 'id'

  const otherProps: OptionalProps = {}

  if (experimentalFeatures) {
    otherProps.experimentalFeatures = experimentalFeatures
  }

  if (editMode) {
    otherProps.editMode = editMode
  }

  if (processRowUpdate) {
    otherProps.processRowUpdate = processRowUpdate
  }
  return (
    <StyleTable
      sx={{
        border: 'none',
      }}
      loading={loading}
      rows={rows}
      columns={columns}
      pageSize={limit}
      rowsPerPageOptions={[limit]}
      autoHeight={true}
      disableColumnFilter
      disableColumnSelector
      getRowId={(row: any) => row?.[id]}
      {...otherProps}
    />
  )
}

export default memo(DataTable)
