import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IResults } from '@/extension-portal/types/employee/entities'

interface ISelectedRows {
  id: string
  isApproved: boolean
  selectedBankAccountId: string
}

export interface IStatements {
  isLoading: boolean
  statements: IResults[]
  page: number
  limit: number
  totalPage: number
  totalUsers: number
  selectedRows: ISelectedRows[]
  isAllRowsSelected: boolean
}

export const initialState: IStatements = {
  isLoading: false,
  statements: [],
  page: 1,
  limit: 10,
  totalPage: 0,
  selectedRows: [],
  totalUsers: 0,
  isAllRowsSelected: false,
}

export const statementReducer = createSlice({
  name: 'statement',
  initialState,
  reducers: {
    setIsLoading: (state: IStatements, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setStatements: (state: IStatements, action: PayloadAction<any[]>) => {
      state.statements = action.payload
    },
    setPage: (state: IStatements, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setLimit: (state: IStatements, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    setTotalPage: (state: IStatements, action: PayloadAction<number>) => {
      state.totalPage = action.payload
    },
    setSelectedRows: (state: IStatements, action: PayloadAction<ISelectedRows[]>) => {
      state.selectedRows = action.payload
    },
    setIsAllRowsSelected: (state: IStatements, action: PayloadAction<boolean>) => {
      state.isAllRowsSelected = action.payload
    },
    setTotalUsers: (state: IStatements, action: PayloadAction<number>) => {
      state.totalUsers = action.payload
    },
  },
})

export const {
  setIsLoading,
  setPage,
  setLimit,
  setTotalPage,
  setSelectedRows,
  setStatements,
  setIsAllRowsSelected,
  setTotalUsers,
} = statementReducer.actions
export default statementReducer.reducer
