import { createTheme } from '@mui/material/styles'
import { BaseDesign } from './BaseDesign/BaseDesign'

const Theme = createTheme({
  palette: {
    primary: {
      main: BaseDesign.colors.primaryColor,
    },
    secondary: {
      main: BaseDesign.colors.secondaryColor,
    },
    info: {
      main: BaseDesign.colors.subTextDark,
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Montserrat',
      textTransform: 'none',
    },
  },
})

Theme.typography.h3 = {
  fontSize: '1.375rem',
  fontWeight: '700',
  textAlign: 'center',
  lineHeight: '27px',
}

Theme.typography.h2 = {
  fontSize: '1.75rem',
  fontWeight: '700',
  lineHeight: '27px',
}

Theme.typography.body2 = {
  fontSize: '0.875rem',
  fontFamily: 'Montserrat',
  fontWeight: '500',
  lineHeight: '17px',
}

export default Theme
