export interface IEndpoint {
  groupName: string
  internalName: string
  endPoint: string
  httpMethod: HttpMethodType
  roles: string[]
}

export enum HttpMethodType {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}
