import React, { useState } from 'react'
import { Button, styled, Box } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import FilterPopover from './FilterPopover'
import { IFilterPopoverWrapper } from '../interface'

export const FilterBox = styled(Box)``
export const FilterButton = styled(Button)`
  color: rgba(255, 255, 255, 0.7);
  background-color: #0e0f29;
  font-size: 10px;
  border: 0.5px dashed #474747;
  border-radius: 20px;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
`

const FilterPopoverWrapper = ({
  label,
  fieldName,
  type,
  onSearchHandler,
  options,
  isMultiple,
}: IFilterPopoverWrapper) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const toggleAnchorEl = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (anchorEl) setAnchorEl(null)
    else setAnchorEl(event.currentTarget)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <>
      <FilterBox component='span'>
        <FilterButton
          data-testId={fieldName}
          aria-describedby={id}
          style={{ textTransform: 'capitalize' }}
          variant='contained'
          onClick={toggleAnchorEl}
          startIcon={<AddCircleIcon />}
        >
          {' '}
          {label}{' '}
        </FilterButton>
        <FilterPopover
          anchorEl={anchorEl}
          handleClose={toggleAnchorEl}
          onSearchHandler={onSearchHandler}
          id={id}
          open={open}
          label={label}
          fieldName={fieldName}
          type={type}
          isMultiple={isMultiple}
          options={options || []}
        />
      </FilterBox>
    </>
  )
}

export default FilterPopoverWrapper
