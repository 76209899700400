import mixpanel from 'mixpanel-browser'
import { USER } from '@/constants/constants'
import { persistentStorage } from '../persistent-storage/persistent-storage'

export const trackEvents = async (event: string, additionalProperties?: any) => {
  try {
    if (!event) return

    const key = process.env.REACT_APP_MIX_PANEL_KEY || ''
    mixpanel.init(key as string, { debug: true })

    const user = JSON.parse(persistentStorage.getItem(USER) || '{}')

    return await mixpanel.track(event, { ...{ ...(additionalProperties || {}), ...user } })
  } catch (error) {
    console.error('can"t able to track any event! ', error)
  }
}
