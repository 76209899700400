import React, { FC } from 'react'
import PaginationComponent from '@mui/material/Pagination'
import { Grid } from '@mui/material'

interface IProps {
  page?: number
  totalPage: number
  onPageChangeHandler: (e: any, newPage: number) => void
}
export const Pagination: FC<IProps> = ({ onPageChangeHandler, totalPage, ...props }) => {
  if (totalPage <= 1) return <></>
  return (
    <Grid>
      <Grid>
        <PaginationComponent
          onChange={onPageChangeHandler}
          count={totalPage}
          page={props?.page}
          variant='outlined'
          shape='rounded'
          data-testId='pagination'
          color='secondary'
        />
      </Grid>
    </Grid>
  )
}
