import React, { FC } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import { useFormContext } from 'react-hook-form'
import FileUpload from 'react-material-file-upload'
import { Grid, styled, Typography } from '@mui/material'

const FileUploadStyled = styled(Grid)`
  border: 1px dashed #a3a5ab;
  border-width: 2px;
  background-color: rgb(14, 15, 41);
  margin-top: 1rem;
  margin-bottom: 2rem;
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.38);
`

interface Props {
  label: string
  width?: string
  fieldName: string
  type?: string
  rest?: any
  handleSelect: any
  multiple: boolean
  files: File[]
}

export const FileField: FC<Props> = ({ label, width, fieldName, ...rest }) => {
  const {
    formState: { errors },
  } = useFormContext() as any
  const props: any = {
    ...rest,
  }
  if (width) props.style = { width }
  const { handleSelect, files } = props
  const inputLabel = label ? label.replace('*', '') : ''
  const inputRequired = label.includes('*') ? '*' : ''
  return (
    <Grid mt={2}>
      {inputLabel && (
        <InputLabel>
          <Typography variant='body2'>
            {inputLabel}&nbsp;
            <span
              style={{
                color: '#FF6347',
                fontSize: '15px',
              }}
            >
              {inputRequired}
            </span>
          </Typography>
        </InputLabel>
      )}
      <FileUploadStyled>
        <FileUpload
          title='Drag & Drop or'
          value={files}
          data-testId={fieldName}
          onChange={handleSelect}
          multiple={rest?.multiple}
          buttonText={'Browse Your PC'}
        />
      </FileUploadStyled>
      {errors && errors[fieldName] && errors[fieldName]?.message && (
        <Grid style={{ color: 'red', marginTop: '10px' }}>
          <Typography>{errors[fieldName]?.message}</Typography>
        </Grid>
      )}
    </Grid>
  )
}
