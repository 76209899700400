import React from 'react'
import { Grid, styled } from '@mui/material'
import ClearFilterBtn from './ClearFilterBtn'
import FilterPopoverWrapper from './FilterPopoverWrapper'
import { FilterType } from '@/strict-typing/enums/portal.enum'
import DateFilter from './Inputs/FilterDateRangeInput'
import { IListingFilter } from '../interface'
import CustomSearchFilter from './CustomSearchInput'
import { INITIATOR } from '@/strict-typing/enums/api-manager.enum'

const FilteredWrapper = styled(Grid)`
  border-top: 1px solid rgba(53, 54, 75, 0.5);
  border-bottom: 1px solid rgba(53, 54, 75, 0.5);
  padding: 0.5rem 0 0 0;
`
const FilterWrapper = styled(Grid)`
  align-item: baseline;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-left: -15px;
`
export const DataTableFilters: React.FC<IListingFilter> = ({
  onSearchHandler,
  onClearFiltersHandler,
  filterList,
  calendar,
  children,
  searchBarParam,
  searchValue,
  setSearchValue
}) => {
  if (!searchBarParam) searchBarParam = 'name'
  if (!Array.isArray(filterList) || !filterList.length) return <></>
  const filters = filterList.map((values, i: number) => {
    if (values.type === FilterType.DATE) {
      return (
        <DateFilter key={i} onSearchHandler={onSearchHandler} calendar={calendar} {...values} />
      )
    } else return <FilterPopoverWrapper key={i} onSearchHandler={onSearchHandler} {...values} />
  })

  const isSearchEnabled = import.meta.env?.VITE_REACT_APP_INITIATOR !== INITIATOR.vendor_web
  return (
    <>
      <FilteredWrapper>
        <Grid>
          <Grid display='flex' justifyContent='start'>
            {isSearchEnabled && (
              <CustomSearchFilter name={searchBarParam} searchHandler={onSearchHandler} searchValue={searchValue??''} setSearchValue={setSearchValue}/>
            )}
            <FilterWrapper>
              <Grid display='flex' flexWrap='wrap'>
                {filters}
              </Grid>
              <Grid display='flex'>
                <ClearFilterBtn clearFilters={onClearFiltersHandler} />
                {children}
              </Grid>
            </FilterWrapper>
          </Grid>
        </Grid>
      </FilteredWrapper>
    </>
  )
}
