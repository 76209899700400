import { Button, styled, CircularProgress } from '@mui/material'
import React from 'react'

import { BaseDesign } from '../../theme/BaseDesign/BaseDesign'

const CustomButton = styled(Button)(
  () => `
  :hover {
    color: #fff;
    background: rgb(163, 165, 171);
  }
`,
)
interface Props {
  disabled?: boolean
  applyfullwidth?: boolean
  content: string
  isLoading?: boolean
  type?: 'submit' | 'button'
  clickHandler?: (val?: any) => void
  testId?: string
}

export const StyledButton: React.FC<Props> = ({
  content,
  applyfullwidth = false,
  isLoading,
  type,
  clickHandler,
  disabled = false,
  testId = '',
}) => {
  const { colors } = BaseDesign
  return (
    <CustomButton
      fullWidth={applyfullwidth}
      variant='contained'
      disabled={disabled}
      {...(typeof clickHandler === 'function' ? { onClick: clickHandler } : {})}
      type={type || 'submit'}
      sx={{
        background: `${colors.themeColorGreen}`,
        color: '#1D1446',
        fontWeight: '600',
      }}
      data-testId={testId}
    >
      {content} {isLoading && <CircularProgress color='secondary' />}
    </CustomButton>
  )
}
