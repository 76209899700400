import React, { FC, useState } from 'react'
import { Button, Grid, InputBase, styled } from '@mui/material'
import Box from '@mui/material/Box'
import SearchIcon from '@mui/icons-material/Search'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { CustomHeader, CustomSearch } from '../StyledHeader'
import { DialogPoper } from '../Popover/DialogPoper'

// Search Feild

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  borderRadius: '32px',
  backgroundColor: '#0E0F29',
  height: '32px',
  width: '150px',
  border: ' 0.5px solid #363748',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}))

// Filter Wrapper style

const FilterWrapper = styled(Grid)`
  align-item: baseline;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-left: -15px;
`

// Filter box style

const FilterBox = styled(Box)`
  border: 0.5px dashed #474747;
  border-radius: 20px;
  text-align: center;
  padding: 5px;
  margin-left: 1rem;
  background-color: #0e0f29;
`

// Filter Button style

const FilterButton = styled(Button)`
  color: rgba(255, 255, 255, 0.7);
  font-size: 10px;
`

interface IListingFilter {
  onSearchHandler: (name: string, val: string) => void
  onClearFiltersHandler: (search: string) => void
  filterList: any[]
  startDate?: Date
  onDateChangeHandler?: (date: Date) => void
}

interface IFilters {
  title: string
  value: string
}

export const ListingFilter: FC<IListingFilter> = ({
  onSearchHandler,
  onClearFiltersHandler,
  filterList,
  startDate,
  onDateChangeHandler,
}) => {
  const [search, setSearch] = useState('')
  const filters = filterList.map(({ title, value }: IFilters, i: number) => {
    if (value === 'date' && startDate && typeof onDateChangeHandler === 'function') {
      return (
        <FilterBox component='span' key={i}>
          <FilterButton>
            <AddCircleIcon sx={{ fontSize: '20px' }} />{' '}
            <DialogPoper {...{ title, index: i, startDate, onDateChangeHandler }} />
          </FilterButton>
        </FilterBox>
      )
    }
    return (
      <FilterBox component='span' key={i}>
        <FilterButton
          data-testId={value}
          onClick={() => onSearchHandler(value, search)}
          startIcon={<AddCircleIcon />}
        >
          {title}
        </FilterButton>
      </FilterBox>
    )
  })
  return (
    <>
      {filterList.length ? (
        <Grid>
          <Grid display='flex' justifyContent='start'>
            <CustomHeader>
              <CustomSearch>
                <SearchIcon />
              </CustomSearch>
              <StyledInputBase
                onChange={({ target }) => {
                  setSearch(target.value)
                }}
                data-testId='searchFilter'
                value={search}
                placeholder='Search…'
                inputProps={{ 'aria-label': 'search' }}
              />
            </CustomHeader>

            {/* Listing Filters */}
            <FilterWrapper>
              <Grid>{filters}</Grid>
              <Grid>
                <FilterButton
                  data-testId='clearFilters'
                  onClick={() => {
                    onClearFiltersHandler(search)
                    setSearch('')
                  }}
                  startIcon={<CancelIcon />}
                >
                  Clear Filters
                </FilterButton>
              </Grid>
            </FilterWrapper>
          </Grid>
        </Grid>
      ) : null}
    </>
  )
}
