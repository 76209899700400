import { Token } from '@/strict-typing/enums/api-manager.enum'
import axios, { AxiosInstance } from 'axios'
import { persistentStorage } from '../persistent-storage/persistent-storage'
import { getBackendFeatureByInternalName } from '@/constants/backend-features/backend-features'
import { IEndpoint, HttpMethodType } from '@/strict-typing/interfaces/api.interface'

export interface GlobalAPIResponse {
  payload: any
  error: boolean
  status: number
}

class useFetch {
  private token: string | undefined | null
  private _instance: AxiosInstance | null
  public initiator: string

  protected constructor(token?: string) {
    this.initiator = import.meta.env.VITE_REACT_APP_INITIATOR
    if (token) this.token = token
    else this.token = persistentStorage.getItem(Token.tokenId)
    this._instance = axios.create({
      baseURL: process.env.STAGE_API_URL,
    })
    this.interceptorRequest()
    this.interceptorResponse()
  }

  setToken(token: string) {
    this.token = token
  }

  async login(username: string, password: string, captchaToken: string, mfaOtp?: string) {
    const login = await this.fetcher('LoginWithExternal', {
      username,
      password,
      initiator: this.initiator,
      captchaToken,
      ...(mfaOtp ? { mfaOtp } : {}),
    })
    if (login?.data.data.redirectUrl) return login.data
    const token = login?.data?.data?.token
    persistentStorage.setItem(Token.tokenId, token)
    if (token) this.setToken(token)
    return this.fetcher('FetchUserProfile', {
      initiator: this.initiator,
    })
  }

  async setupMFA(username: string, password: string) {
    const generatedMFAData = await this.fetcher('MFAGenerate', {
      username,
      password,
    })

    return generatedMFAData?.data
  }

  promiseHandler(pro: Promise<any>) {
    if (pro)
      return pro
        .then((res) => this.ResponseHandler(res?.data, false))
        .catch((err) => this.ResponseHandler(err?.response, true))
    throw new Error('Instance Is not promise')
  }

  ResponseHandler = (response: any, error: boolean): GlobalAPIResponse => {
    if (error) {
      return {
        error: true,
        payload: response?.data,
        status: response?.status,
      }
    }
    return {
      error: false,
      payload: response,
      status: 200,
    }
  }

  interceptorRequest() {
    return this._instance?.interceptors.request.use(
      async (config: any) => {
        config.url = config.baseURL + config.url
        config.header = config.headers
        if (this.token) {
          config.headers.Authorization = `Bearer ${this.token}`
        }
        return config
      },
      function (error) {
        return Promise.reject(error)
      },
    )
  }

  interceptorResponse() {
    return this._instance?.interceptors.response.use(function (response) {
      if (response.status === 401) {
        throw Error('Unauthorized')
      }
      return response
    })
  }

  fetcher(name: string, params?: Record<any, any>) {
    const options: IEndpoint = { ...getBackendFeatureByInternalName(name) }
    let param = params
    if (params?.pathVariables) {
      const { pathVariables, ...rest } = params
      param = rest
      Object.keys(pathVariables).forEach((pathVariable: string) => {
        options.endPoint = `${options.endPoint}`.replace(
          `{${pathVariable}}`,
          params.pathVariables[pathVariable],
        )
      })
    }
    switch (options?.httpMethod) {
      case HttpMethodType.GET: {
        return this._instance?.get(
          options?.endPoint,
          params?.pathVariables
            ? {
                params: param,
              }
            : {
                params,
              },
        )
      }
      case HttpMethodType.POST: {
        return this._instance?.post(options?.endPoint, params?.pathVariables ? param : params)
      }
      case HttpMethodType.PUT: {
        return this._instance?.put(options?.endPoint, params?.body)
      }
      case HttpMethodType.PATCH: {
        return this._instance?.patch(options?.endPoint, params?.body)
      }
      case HttpMethodType.DELETE: {
        return this._instance?.delete(
          options?.endPoint,
          params?.pathVariables
            ? {}
            : {
                data: params,
              },
        )
      }
      default: {
        throw new Error('Maybe Method or Backend Feature not implement!')
      }
    }
  }
}

export class useFetchSingleton extends useFetch {
  private static _instance: useFetchSingleton

  private constructor(token?: string) {
    super(token)
  }

  static getInstance(token?: string): useFetchSingleton {
    if (!useFetchSingleton._instance) useFetchSingleton._instance = new useFetchSingleton(token)
    return useFetchSingleton._instance
  }

  static clone(token?: string): useFetchSingleton {
    return new useFetchSingleton(token)
  }
}
