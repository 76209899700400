import React, { FC } from 'react'
import { Checkbox, FormControlLabel, Grid, styled, TextField, Typography } from '@mui/material'
import InputLabel from '@material-ui/core/InputLabel'
import { useFormContext, Controller } from 'react-hook-form'

interface IStyledForm {
  errors: any
  fieldName: string
}
const StyledForm = styled(TextField)(({ errors, fieldName }: IStyledForm) => ({
  input: { color: '#E2E2E2' },
  border: `${errors && errors[fieldName] ? '1px solid red' : '1px solid rgba(163, 165, 171, 0.2)'}`,
  backgroundColor: '#0E0F29',
  borderRadius: '8px',
  width: '100%',
  marginTop: '8px',
  padding: 0,
}))

interface Props {
  label: string
  width?: string
  fieldName: string
  type?: string
  rest?: any
  handleToggle: () => void
  showPassword: boolean
}

export const PasswordField: FC<Props> = ({
  label,
  width,
  fieldName,
  type,
  handleToggle,
  showPassword,
  ...rest
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext() as any
  const props: any = {
    ...rest,
  }
  if (width) props.style = { width }
  return (
    <>
      {label && (
        <InputLabel>
          <Typography variant='body2'>{label}</Typography>
        </InputLabel>
      )}
      <Controller
        name={fieldName}
        control={control}
        render={({ field }) => (
          <StyledForm
            {...{
              type: type || 'text',
              errors,
              ...field,
              ...props,
            }}
          >
            <TextField />
          </StyledForm>
        )}
      />
      <FormControlLabel
        control={<Checkbox onChange={handleToggle} />}
        label={
          showPassword ? (
            <Typography>Show Password</Typography>
          ) : (
            <Typography>Show Password</Typography>
          )
        }
      />
      {errors && errors[fieldName] && errors[fieldName]?.message && (
        <Grid style={{ color: 'red', marginTop: '10px' }}>
          <Typography>{errors[fieldName]?.message}</Typography>
        </Grid>
      )}
    </>
  )
}
