import React from 'react'
import { FormGroup, FormControlLabel, Checkbox, Grid, Divider, Radio } from '@mui/material'
import { FilterType } from '@/strict-typing/enums/portal.enum'
import { CustomButton } from '@/views/base/StyledButton/CustomButton'

interface IProps {
  fieldName: string
  onSearchHandler: (f: string, v: string, t: FilterType) => void
  closePopover: (e: React.MouseEvent<HTMLButtonElement>) => void
  type: FilterType
  options: string[]
  isMultiple?: boolean
}

const FilterCheckboxInput = ({
  fieldName,
  onSearchHandler,
  closePopover,
  type,
  options,
  isMultiple = true,
}: IProps) => {
  if (!options || !options.length) return <></>

  const [search, setSearch] = React.useState('')
  const [selectedValue, setSelectedValue] = React.useState('')
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const isChecked = e.target.checked
    if (!isMultiple) setSearch(value)
    else {
      if (isChecked) {
        const addSearch = search ? `${search},${value}` : value
        setSearch(addSearch)
      } else {
        const hasValue = search.includes(value)
        if (hasValue) {
          const searchStr = search
            .split(',')
            .filter((val: string) => val !== value)
            .join(',')
          setSearch(searchStr)
        }
      }
    }
  }

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (search) {
      onSearchHandler(fieldName, search, type)
      setTimeout(() => {
        closePopover(e)
      }, 1000)
    }
  }

  const formFields = options.map((option: string, i: number) => (
    <Grid key={i}>
      <FormControlLabel
        className='text-box-label'
        control={
          isMultiple ? (
            <Checkbox onChange={(e) => changeHandler(e, option)} />
          ) : (
            <Radio
              checked={selectedValue === option}
              className='filterRadioButton'
              onChange={(e) => {
                setSelectedValue(option)
                changeHandler(e, option)
              }}
            />
          )
        }
        label={option}
      />
      <Divider color='grey' />
    </Grid>
  ))

  return (
    <>
      <FormGroup>{formFields}</FormGroup>
      <CustomButton title='Apply' applyFullWidth={true} clickHandler={submitHandler} />
    </>
  )
}

export default FilterCheckboxInput
