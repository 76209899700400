import React, { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { InputField, SelectField } from '..'
import { StyledButton } from '@/views/base'
import styled from 'styled-components'

const AccountContainer = styled(Grid)`
  border: 1px solid rgba(163, 165, 171, 0.2);
  background-color: #0e0f29;
  border-radius: 8px;
  margin-top: 1rem;
  padding: 1rem;
`

const AccountDetailWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

interface FetchTitleFormProps {
  accountNumber: string
  bankName: string
}

interface IAccountInfo {
  bank: {
    bankName: string
  }
  accountNumber: string
  accountTitle: string
}

interface Props {
  fetchTitleHandler: (values: FetchTitleFormProps) => void
  menuItems: any[]
  disabled?: boolean
  accountInfo: IAccountInfo | any
}

export const FetchTitleForm: FC<Props> = ({
  fetchTitleHandler,
  menuItems,
  disabled,
  accountInfo,
}) => {
  const options = menuItems.map((item: any) => (
    <option key={item?.id} value={item?.bankName}>
      {item?.bankName}
    </option>
  ))
  return (
    <>
      <Grid lineHeight='3' pl={2} pr={4}>
        <Typography variant='h6'>Bank Information</Typography>
        <SelectField
          fieldName='bankName'
          label='Bank Name*'
          isSelectOpt={true}
          options={options}
          defaultOption='Select Bank'
        />
        <AccountDetailWrapper>
          <Grid width={600} display='flex' flexDirection='column'>
            <Grid>
              <InputField
                label='Account Number*'
                fieldName='accountNumber'
                placeHolder='Account Number'
              />
            </Grid>
            {/* Account Details */}

            {!!Object.keys(accountInfo).length && (
              <AccountContainer>
                <AccountDetailWrapper>
                  <Typography>
                    <b>Bank Name: </b>
                  </Typography>
                  <Typography>{accountInfo?.bank?.bankName}</Typography>
                </AccountDetailWrapper>
                <AccountDetailWrapper>
                  <Typography>
                    <b>Account Title: </b>
                  </Typography>
                  <Typography>{accountInfo.accountTitle}</Typography>
                </AccountDetailWrapper>
                <AccountDetailWrapper>
                  <Typography>
                    <b>Bank Number: </b>
                  </Typography>
                  <Typography>{accountInfo.accountNumber}</Typography>
                </AccountDetailWrapper>
              </AccountContainer>
            )}
          </Grid>
          <Grid mt={5}>
            <StyledButton
              disabled={disabled}
              clickHandler={fetchTitleHandler}
              content='Fetch Account'
              testId='fetchAccount'
              type='button'
            />
          </Grid>
        </AccountDetailWrapper>
      </Grid>
    </>
  )
}
