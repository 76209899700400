import { PayrollListItem, PayrollListItemWithNo } from '@/extension-portal/types/payroll/entities'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IPayroll {
  page: number
  limit: number
  totalPage: number
  isAllRowsSelected: boolean
  payrollData: Array<PayrollListItemWithNo> | []
  isLoading:boolean
}

export const initialState: IPayroll = {
  page: 1,
  limit: 10,
  totalPage: 0,
  isAllRowsSelected: false,
  payrollData: [],
  isLoading:false
}

export const payrollReducer = createSlice({
  name: 'payroll',
  initialState,
  reducers: {
    setPage: (state: IPayroll, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setLimit: (state: IPayroll, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    setTotalPage: (state: IPayroll, action: PayloadAction<number>) => {
      state.totalPage = action.payload
    },
    setPayrollData:(state: IPayroll, action: PayloadAction<PayrollListItemWithNo[]>) => {
      state.payrollData = action.payload
    },
    setIsLoading:(state: IPayroll, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    }
  },
})

export const { setPage, setLimit, setTotalPage, setPayrollData, setIsLoading} = payrollReducer.actions

export default payrollReducer.reducer
