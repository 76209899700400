export const ASSETS_BASE_URL = 'https://af-public.s3.eu-west-1.amazonaws.com'
export const ASSETS_BASE_URL_UAE = 'https://af-uae-dev-v2-public.s3.me-central-1.amazonaws.com'
export const ADD_BULK_VIDEO_DEMO = 'https://www.youtube.com/pages/category/how-to-add-in-bulk/'
export const RECAPTCHA_SITE_KEY = '6LebwMQhAAAAAJDkKxNMLPGwiFNvvzzaQpgO4irH'
export const USER_ROLE = '@@user_role@@'
export const ORGANIZATION_INFO = '@@organization_info@@'
export const RECAPTCHA_SITE_KEY_TESTING = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
export const MAX_UPLOAD_FILE_SIZE = 5 * 1024 * 1024 // 5 Mb
export const USER = '@@loggedIn_user@@'

export const MFA_SETUP_URL = '/mfa/setup'
export const MFA_OTP_URL = '/mfa/otp'
export const QR_CODE_BASE_URL = 'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data='